.oh-hell__start-button {
  margin: 24px var(--padding-start) 0;
}

.oh-hell__players {
  margin-bottom: 24px;
}

.reload-game {
  --white-space: 0;

  .toast-content {
    padding-inline: 0;
  }
}