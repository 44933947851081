.floating-button {
  margin: 12px;
}

.oh-hell__round {
  .col-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
  }

  .oh-hell__player {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >div {
      display: flex;
      align-items: center;

      >ion-icon {
        margin-right: 8px;
      }
    }
  }
}